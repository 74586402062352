export default function Home() {
  return (
    <div class="body-background">
      <div class="header header-home">
        <div class="card card-home">
          <h6>Bienvenidos</h6>
          <div class="span-intro">
            Estamos trabajando en nuestra web 🏗️.
            <br />
            Pronto vas a poder conocer más acerca de nuestros servicios.
          </div>
        </div>
      </div>
    </div>
  );
}
