import { findByType } from '../../utils';

export default function useSubcomponentFinding(children) {
    return (subcomponent, renderFunc) => {
      const element = findByType(children, subcomponent);
      if (!element) {
        return null;
      }
  
      return renderFunc(element.props.children);
    };
  }
  