import { Children } from "react";

export default function findByType(children, component) {
  let result;
  const type = component.displayName ?? component.name;
  Children.forEach(children, (child) => {
    const childType = child?.type?.displayName ?? child?.type?.name;
    if (type === childType) {
      result = child;
      return;
    }
  });
  return result;
}
