import { useSubcomponentFinding } from "../../../hooks";

const Emoji = () => null;
const Title = () => null;
const Intro = () => null;
const Footer = () => null;

function Message({ isCard, children }) {
  const stylePrefix = isCard ? "card" : "contenido";

  const renderFunc = useSubcomponentFinding(children);

  const emojiElement = renderFunc(Emoji, (child) => (
    <div class="emoji">{child}</div>
  ));

  const titleElement = renderFunc(Title, (child) => <h1>{child}</h1>);

  const introElement = renderFunc(Intro, (child) => (
    <div class="span-intro">{child}</div>
  ));

  const footerElement = renderFunc(Footer, (child) => <p>{child}</p>);

  return (
    <div class="header header-home">
      <div class={`${stylePrefix} ${stylePrefix}-home`}>
        {emojiElement}
        {titleElement}
        {introElement}
        {footerElement}
      </div>
    </div>
  );
}

Message.Emoji = Emoji;
Message.Title = Title;
Message.Intro = Intro;
Message.Footer = Footer;

export default Message;
