import Message from "../../templates/Message";

export default function NotFound() {
  return (
    <Message isCard>
      <Message.Emoji>🤷</Message.Emoji>
      <Message.Title>¡Uy! No encontramos esa página.</Message.Title>
      <Message.Intro>404</Message.Intro>
      <Message.Footer>
        Si necesitás más ayuda escribinos a{" "}
        <a href="mailto:hello@nubiz.app">hello@nubiz.app</a>
      </Message.Footer>
    </Message>
  );
}
